<div class="d-flex flex-row justify-content-center">

  <div class="d-flex flex-column align-items-center mt-4 mx-3 col-12 col-md-4">

    <h3>ACCESS INSTRUCTIONS FOR YOUR TOTAL MATERIA ACCOUNT</h3>

    <p class="mt-4">
      Based on your account configuration, in order to access your account it is required to identify the primary user
      machine from which access will be made. This is done by using a special application that will anonymously identify
      the machine.
    </p>
    <p>
      In case you are not redirected automatically to the Total Materia website then please carry out the following
      steps:
    </p>

    <ol>
      <li>Download the installation file by clicking on the link below: <br />
        <a class="btn btn-link" href="https://download.totalmateria.com/TotalMateriaSingleSeatAccess.exe">Download Total Materia application</a>
      </li>
      <li>Install the application by running the downloaded file (you may need to have administrative access to install
        it)</li>
      <li>Run the application by clicking on the following link: <br/> <button class="btn btn-link py-0" (click)="openApp()">Proceed to Total
          Materia</button></li>
    </ol>
  </div>

</div>