<div class="container-login-register">
  <div class="img-display"><app-auth-image class="displayed-img" [imageName]="'Altair-logout.png'"></app-auth-image></div>
  <div class="login-form">
    <div class="form-container">
        <fieldset>
          <legend>
            Logout
          </legend>
          <div>You have successfully logged out of the Total Materia Premium Edition.</div>
          <div>In order to log back in please use the Total Materia icon on your desktop.</div>
        </fieldset>
    </div>
  </div>
</div>
