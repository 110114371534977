import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request URL is for the /login page
    if (req.url.includes('/login')) {
      const token = this.tokenExtractor.getToken() as string;
      if (token !== null && !req.headers.has('X-XSRF-TOKEN')) {
        req = req.clone({ headers: req.headers.set('X-XSRF-TOKEN', token) });
      }
    }
    return next.handle(req);
  }
}
