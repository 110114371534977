<div class="container-login-register">
  <div class="img-display">
    <app-auth-image class="displayed-img" [imageName]="'bg-portrat-1.jpg'"
      aria-label="Authentication image"></app-auth-image>
  </div>
  <div class="login-form" aria-label="Login form">
    <div class="form-container mb-5">
      <form [formGroup]="registerForm" aria-label="Registration form">
        <fieldset>
          <legend><strong>Register</strong></legend>
          <p>Simply register to gain instant trial access to Total Materia!</p>
          <div class="form-grid" aria-label="Form grid">
            <div class="personal-info">
              <div class="form-group">
                <label for="firstName">First Name:</label>
                <input type="text" class="form-control" id="firstName" formControlName="firstName"
                  placeholder="*First Name" [ngClass]="{ 'invalid-input': isFieldInvalid('firstName') }"
                  aria-label="First Name">
              </div>

              <div class="form-group">
                <label for="lastName">Last Name:</label>
                <input type="text" class="form-control" id="lastName" formControlName="lastName"
                  placeholder="*Last Name" [ngClass]="{ 'invalid-input': isFieldInvalid('lastName') }"
                  aria-label="Last Name">
              </div>

              <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" class="form-control" id="email" formControlName="email" placeholder="*Email"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('email') }" aria-label="Email">
              </div>

              <div class="form-group">
                <label for="confirmEmail">Confirm Email:</label>
                <input type="email" class="form-control" id="confirmEmail" formControlName="confirmEmail"
                  placeholder="*Confirm Email" [ngClass]="{ 'invalid-input': isFieldInvalid('confirmEmail') }"
                  aria-label="Confirm Email">
                <div *ngIf="isFieldInvalid('confirmEmail')" class="invalid-feedback">Emails do not match</div>
              </div>

            
              <div class="form-group">
                <label for="phone">Phone:</label>
                <input type="text" class="form-control" id="phone" formControlName="phone" placeholder="*Phone"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('phone') }" aria-label="Phone">
              </div>
              <div class="form-group">
                <label for="profession">Profession:</label>
                <select class="form-control" id="profession" formControlName="profession"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('profession') }" aria-label="Profession">
                  <option value="">*Select Profession</option>
                  <option *ngFor="let profession of professions" [value]="profession">{{ profession }}</option>
                </select>
              </div>
            </div>
            <div class="company-info">
              <div class="form-group">
                <label for="company">Company:</label>
                <input type="text" class="form-control" id="company" formControlName="company" placeholder="*Company"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('company') }" aria-label="Company">
              </div>

             

              <div class="form-group">
                <label for="industry">Industry:</label>
                <select class="form-control" id="industry" formControlName="industry"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('industry') }" aria-label="Industry">
                  <option value="">*Select Industry</option>
                  <option *ngFor="let industry of industries" [value]="industry">{{ industry }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="country">Country:</label>
                <select class="form-control" id="country" formControlName="country"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('country') }" aria-label="Country">
                  <option value="">*Select Country</option>
                  <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="province">Province:</label>
                <input type="text" class="form-control" id="province" formControlName="province" placeholder="Province"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('province') }" aria-label="Province">
              </div>  
             
              <div class="form-group">
                <label for="city">City:</label>
                <input type="text" class="form-control" id="city" formControlName="city" placeholder="*City"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('city') }" aria-label="City">
              </div>
              <div class="form-group">
                <label for="zipCode">Zip Code:</label>
                <input type="text" class="form-control" id="zipCode" formControlName="zipCode" placeholder="*Zip Code"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('zipCode') }" aria-label="Zip Code">
              </div>
             
              <div class="form-group">
                <label for="address">Address:</label>
                <input type="text" class="form-control" id="address" formControlName="address" placeholder="Address"
                  [ngClass]="{ 'invalid-input': isFieldInvalid('address') }" aria-label="Address">
              </div>
             

            </div>
          </div>
          <div class="form-group checkboxes py-2">
            <label class="mb-3">Please indicate the primary information you are looking for from the options listed
              below:</label>
            <div class="checkbox-grid" aria-label="Checkbox grid">
              <div class="checkbox-column" aria-label="Checkbox column 1">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" formControlName="checkboxCrossReference"
                      aria-label="Material Cross Reference"> Material Cross Reference
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" formControlName="checkboxMetalSuppliers" aria-label="Metal Suppliers"> Metal
                    Suppliers
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" formControlName="checkboxGeneralMetalProperties"
                      aria-label="General Metal Properties"> General Metal Properties
                  </label>
                </div>
              </div>
              <div class="checkbox-column" aria-label="Checkbox column 2">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" formControlName="checkboxPlasticCompositesCeramics"
                      aria-label="Plastic-Composites-Ceramics"> Plastic, Composites or Ceramics
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" formControlName="checkboxAdvancedProperties"
                      aria-label="Advanced Properties"> Advanced Properties
                  </label>
                </div>
                <div class="checkbox">
                  <label>
                    <input type="checkbox" formControlName="checkboxOther" aria-label="Other"> Other
                  </label>
                </div>
              </div>
            </div>
            <label class="mt-3">As a part of your Free Trial registration we will sign you up for our newsletter. Please
              uncheck the below box if you do not want to receive monthly news updates from us.</label>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="checkboxNews" aria-label="Receive TOTAL MATERIA News"> Yes, I'm
                interested to get monthly TOTAL MATERIA News
              </label>
            </div>
          </div>
          <button type="button" class="login-button" (click)="onSubmit()" aria-label="Register button">Try for
            free</button>

          <label class="mt-3">We guarantee that your personal data will be kept as strictly confidential and not given
            away under any circumstances.</label>
        </fieldset>
      </form>
    </div>
  </div>
</div>