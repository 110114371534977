import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginClientService } from '../loginClient/loginClient.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorId: string = '';  // Declare a property to hold the errorId

  constructor(private route: ActivatedRoute, private client: LoginClientService) { }  // Inject ActivatedRoute

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.errorId = params['errorId'];  // Assign the errorId from the parameters
      if (this.errorId) {
        this.client.getError(this.errorId).subscribe(res => {
          console.log(res);
        });
      }
    });
  }

}
