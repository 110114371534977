import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-auth-image',
  templateUrl: './AuthImage.component.html',
  styleUrls: ['./AuthImage.component.scss']
})
export class AuthImageComponent implements OnInit {

  @Input() imageName = 'black-cubes.jpg';

  imagePath = '';

  currentYear = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
    this.imagePath = `../../../assets/images/Login/${this.imageName}`;
  }
}