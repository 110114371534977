<div class="d-flex flex-row justify-content-center">

  <div class="d-flex flex-column align-items-center mt-4 mx-3 col-12 col-md-4">

    <h3>FAILED LOGIN TO YOUR TOTAL MATERIA ACCOUNT</h3>

    <p class="mt-4">
      You are trying to access Total Materia from a machine that is different from the one that is registered for your
      account.


    </p>
    <p>
      If you wish to change the registered machine (i.e. changing computer) please contact us to remove the old
      registration record.

    </p>
  </div>

</div>