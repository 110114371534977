import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISingleUserConfig } from 'src/app/model/singleUser/ISingleUserConfig';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SingleUserClientService {

constructor(private httpClient: HttpClient) { }

getSingleUserConfig(): Observable<ISingleUserConfig>{
  const url = `${environment.serverUrl}/api/singleuser/config`;
  return this.httpClient.get<ISingleUserConfig>(url);
}

}
