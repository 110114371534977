import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginClientService {
 
  constructor(private http: HttpClient) { }

  login(username: string, password: string): Observable<any> {
    const apiUrl = `${environment.serverUrl}/website/login`;
    const body = {
      username: username,
      password: password,
    };
  
    // Retrieve the CSRF token from the cookie
    const xsrfToken = this.getCookie('XSRF-TOKEN') || '';
  
    // Set the X-XSRF-TOKEN header to the retrieved CSRF token
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': xsrfToken,
    });
  
    // Send the request with the X-XSRF-TOKEN header included
    return this.http.post(apiUrl, body, { headers: headers, withCredentials: true, responseType: 'text' }).pipe(
      map((response) => {
        // Process the response as needed
        return response;
      })
    );
  }
  
  getError(errorId: string) {
    const apiUrl = `${environment.serverUrl}/error/${errorId}`;

    return this.http.get(apiUrl, { responseType: 'text' });
  }

  getIpAddress(): Observable<any> {
    return this.http.get(`${environment.serverUrl}/website/ipaddress`);
  }
  
  private getCookie(name: string): string {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift() || '';
    }
    return '';
  }
  
}