import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginClientService } from '../loginClient/loginClient.service';
import { CsrfService } from '../loginClient/csrf.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  spinning = false;
  currentEmoji: string = '';
  ipAddress: string | null = null;
  loginForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginClientService,
    private csrfService: CsrfService,
    private http: HttpClient
  ) {
   
  }

  ngOnInit() {
    this.spinning = false;
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.csrfService.getCsrfToken().subscribe((token: string) => {
      this.csrfService.storeToken(token);
    });
    this.getIpAddress();
  }

  getIpAddress() {
    this.loginService.getIpAddress().subscribe((response: any) => {
      this.ipAddress = response.ip;
      console.log('IP Address:', this.ipAddress);
    });
  }


  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
  
    this.currentEmoji = '⏳'; // Show progress by emoji
    this.spinning = true;
  
    const { username, password } = this.loginForm.value;
  
    this.loginService.login(username, password).subscribe({
      next: (response) => {
        setTimeout(() => {
          this.spinning = false;
        }, 2000); // Wait for 2 seconds before stopping spinner animation

        console.log(response);
        window.location.href = JSON.parse(response).url;
      },
      error: (error) => {
        setTimeout(() => {
          this.spinning = false;
        }, 2000); // Wait for 2 seconds before stopping spinner animation
      },
    });
  }
  
}
