import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './authenticationLogin/login/login.component';
import { SingleUserErrorComponent } from './singleUser/singleUserError/singleUserError.component';
import { SingleUserInfoComponent } from './singleUser/singleUserInfo/singleUserInfo.component';
import { RegisterComponent } from './authenticationLogin/register/register/register.component';
import { AltairComponent } from 'src/app/logout/altair/altair.component';
import { ErrorComponent } from './authenticationLogin/error/error.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'singleUser/info',
        component: SingleUserInfoComponent,
      },
      {
        path: 'singleUser/info/:sessionId',
        component: SingleUserInfoComponent,
      },
      {
        path: 'singleUser/error',
        component: SingleUserErrorComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'altairlogout',
        component: AltairComponent,
      },
      {
        path: 'home/error',
        component: ErrorComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
