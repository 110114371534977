import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

function emailMatchValidator(control: AbstractControl): ValidationErrors | null {
  const emailControl = control.get('email');
  const confirmEmailControl = control.get('confirmEmail');
      
  if (!emailControl || !confirmEmailControl) {
    return null;
  }

  if (emailControl.value !== confirmEmailControl.value) {
    confirmEmailControl.setErrors({ emailMismatch: true });
  } else {
    confirmEmailControl.setErrors(null);
  }

  return null;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm!: FormGroup;
  currentYear: number;
  countries: string[] = ['Country 1', 'Country 2', 'Country 3'];
  industries: string[] = ['Industry 1', 'Industry 2', 'Industry 3'];
  professions: string[] = ['Profession 1', 'Profession 2', 'Profession 3'];

  constructor(private formBuilder: FormBuilder) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      company: ['', Validators.required],
      address: [''],
      city: ['', Validators.required],
      province:[], 
      zipCode: ['', Validators.required],
      phone: ['', Validators.required],
      profession: ['', Validators.required],
      industry: ['', Validators.required],
      checkboxCrossReference: [false],
      checkboxMetalSuppliers: [false],
      checkboxGeneralMetalProperties: [false],
      checkboxPlasticCompositesCeramics: [false],
      checkboxAdvancedProperties: [false],
      checkboxOther: [false],
      checkboxNews: [false],
    }, { validators: emailMatchValidator });
  }
  
  onSubmit() {
    this.registerForm.markAllAsTouched();
    this.registerForm.updateValueAndValidity();
  
    if (this.registerForm.valid) {
      console.log(this.registerForm.value);
    } else {
      console.log('Form is not valid');
    }
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.registerForm.get(fieldName);
    return field ? (field.invalid && (field.dirty || field.touched)) : false;
  }
//function that will check if email inpt is in valid format 
  isEmailInvalid(): boolean {
    const email = this.registerForm.get('email');
    return email ? (email.invalid && (email.dirty || email.touched)) : false;
  }
}
