import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-altair',
  templateUrl: './altair.component.html',
  styleUrls: ['./altair.component.css']
})
export class AltairComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
