<div class="auth-img" [style.backgroundImage]="'url(' + imagePath + ')'">
  <div class="image-text-container">
    <div class="image-text"></div>
    <div class="footer">
      <!-- Activate again when new website is launched and pages below created    -->
      <!-- <nav class="footer-menu">
        <a href="#">Home</a>
        <a href="#">Terms of Use</a>
        <a href="#">Sitemap</a>
        <a href="#">Contact Us</a>
      </nav> -->
      <div class="copyright-claim">
        &copy; {{currentYear}} Total Materia AG. All rights reserved.
      </div>
    </div>
  </div>
</div>
