import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CsrfService {
  private csrfToken: string | null = null;

  constructor(private http: HttpClient) {}

  getCsrfToken(): Observable<string> {
    return this.http.get(`${environment.serverUrl}/website/generate-csrf-token`, { withCredentials: true, responseType: 'text' }).pipe(
      map((token: string) => {
        this.csrfToken = token;
        return token;
      })
    );
  }

  getCsrfTokenSync(): string | null {
    return this.csrfToken;
  }

  storeToken(token: string): void {
    localStorage.setItem('XSRF-TOKEN', token);
  }

  getStoredToken(): string | null {
    return localStorage.getItem('XSRF-TOKEN');
  }
}
