import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-user-error',
  templateUrl: './singleUserError.component.html',
  styleUrls: ['./singleUserError.component.scss']
})
export class SingleUserErrorComponent {

  constructor() { }
}
