import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SingleUserClientService } from '../singleUserClient/singleUserClient.service';

@Component({
  selector: 'app-single-user-info',
  templateUrl: './singleUserInfo.component.html',
  styleUrls: ['./singleUserInfo.component.scss']
})
export class SingleUserInfoComponent implements OnInit {

  constructor(private route: ActivatedRoute, private singleUserClient: SingleUserClientService) { }

  ngOnInit() {
    this.openApp();
  }

  openApp() {
    let sessionId = this.route.snapshot.queryParams['sessionId'];

    if (sessionId) {
      this.singleUserClient.getSingleUserConfig().subscribe(res => {
        let appUrl = `tm-sua://${res.serverUrl}/Login/LoginWithSession?sessionId=${sessionId}&lng=en&ForceOld=0`;
        window.open(appUrl, '_self');
      });
    }
  }
}
