import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SingleUserInfoComponent } from './singleUser/singleUserInfo/singleUserInfo.component';
import { SingleUserErrorComponent } from './singleUser/singleUserError/singleUserError.component';
import { LoginComponent } from './authenticationLogin/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CsrfService } from './authenticationLogin/loginClient/csrf.service';
import { CsrfInterceptor } from './authenticationLogin/loginClient/csrfInterceptor';
import { RegisterComponent } from './authenticationLogin/register/register/register.component';
import { AuthImageComponent } from './authenticationLogin/AuthImage/AuthImage.component';
import { AltairComponent } from 'src/app/logout/altair/altair.component';
import { ErrorComponent } from './authenticationLogin/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    SingleUserInfoComponent,
    SingleUserErrorComponent,
    LoginComponent,
    RegisterComponent,
    AuthImageComponent,
    AltairComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],
  providers: [
    CsrfService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

